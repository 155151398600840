import Head from "next/head";

export default function RichSnippetHome() {
    const data: any = {
        "@context": "https://schema.org/",
        "@type": "Corporation",
        "name": "PerfectHair.ch",
        "legalName": "PerfectHair AG",
        "url": "https://www.perfecthair.ch/",
        "logo": "https://cdn.perfecthair.ch/media/vector/59/fd/62/perfecthair-logo-v1-rgb-turquoise.svg",
        "description": "Mit über 300 Marken und über 30’000 Produkten sind wir der grösste Schweizer Online Shop für professionelle Haar- und Beautyprodukte.",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Wallisellen",
            "addressRegion": "Zürich",
            "postalCode": "8304",
            "streetAddress": "Hertistrasse 27",
            "addressCountry": "CH"
        },
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "customer service",
            "areaServed": "CH",
            "telephone": "+41 52 544 16 00",
            "email": "shop@perfecthair.ch",
            "availableLanguage": ["German", "English", "French"]
        },
        "sameAs": [
            "https://www.trustedshops.ch/bewertung/info_XDE0696BEF034B75F0C5DDB6B84F78687.html",
            "https://www.instagram.com/perfecthairch/",
            "https://www.facebook.com/PerfectHairOnlineShop/",
            "https://www.pinterest.com/perfecthair/",
            "http://www.linkedin.com/company/perfecthair-ag",
            "https://www.youtube.com/user/TeamPerfectHair",
            "https://goo.gl/maps/oqUTujtfjRCVEFKA8"
        ]
    }

    return (
        <Head>
            <script type={"application/ld+json"} key={'rs-corp'}
                    dangerouslySetInnerHTML={{__html: JSON.stringify(data)}}/>
        </Head>
    )
}