import React from "react";
import BxProductSlider from "./ProductSlider";
import DailyDeal from "../daily-deal/DailyDeal";
import single from "../../utils/single";

interface Props {
    narrative: any;
    dailyDeal?: any;
}

export default function BxDesktopRow({ narrative, dailyDeal }: Props) {
    const productSliderBlock = narrative.blocks.find((b: any) => single(b.name) === 'ProductSlider');
    const dailyDealBlock = narrative.blocks.find((b: any) => single(b.name) === 'DailyDeal')

    return <div className="container">
        <div className="row ms-auto me-auto mt-5">
            <div className={`${dailyDeal ? 'col-12 col-md-4 col-xl-3' : 'col-0'}`}>
                <DailyDeal blockData={dailyDealBlock} dailyDeal={dailyDeal}/>
            </div>
            <div className={`${dailyDeal ? 'col-12 col-md-8 col-xl-9' : 'col-12'}`}>
                <BxProductSlider narrative={productSliderBlock} dailyDeal={dailyDeal}/>
            </div>
        </div>
    </div>;
}
