import type {NextPage} from 'next'
import React, {useEffect} from "react";
import i18next from "i18next";
import tagManager from "../services/tagManager";
import cookieService from "../services/cookie/cookieService";
import Layout from '../components/layouts/Layout';
import BxHomeNarratives from '../components/boxalino/HomeNarratives';
import RichSnippetHome from "../components/rich/snippet/Home";
import apiInternal from "../utils/apiInternal";
import contextRequestUrl from "../utils/contextRequestUrl";
import getClientIp from "../utils/getClientIp";
import redirectService from "../services/redirect/redirectService";

const Home: NextPage = (context: any) => {
    useEffect(() => {
        tagManager.pageTags({
            category: 'Index',
            countryCode: `${i18next.language}_CH`,
            languageCode: i18next.language,
            pageTitle: 'PerfectHair.ch',
        }, 'home');
    });

    return (
        <>
            <RichSnippetHome/>
            <Layout context={context}>
                <BxHomeNarratives bxHome={context.bxHome} dailyDeal={context.dailyDeal}/>
            </Layout>
        </>
    )
}

export async function getServerSideProps(context: any) {
    context.res.setHeader(
        'Cache-Control',
        'public, s-maxage=10, stale-while-revalidate=60'
    );

    let resListing;

    try {
        resListing = await apiInternal().post('/api/v2/pages/index/listing', {
            customerGroup: cookieService.getCustomerGroup(context),
            language: context.locale,
        }, {
            headers: {
                cookie: cookieService.stringify(context.req.cookies),
                referer: context.req.headers.referer ?? '',
                'user-url': contextRequestUrl(context),
                'user-agent': context.req.headers['user-agent'] ?? '',
                'x-forwarded-for': getClientIp(context.req) ?? '',
                'accept-language': context.req.headers["accept-language"]
            }
        })
    } catch (e) {
        console.error('index listing data fetch error', e)
        return redirectService.redirectTo404();
    }

    return {props: resListing.data}
}

export default Home
