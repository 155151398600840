import React from "react";
import BxHomeNarrativeBlock from "./HomeNarrativeBlock";

interface Props {
    bxHome: any;
    dailyDeal: any;
}

export default function BxHomeNarratives({ bxHome, dailyDeal }: Props) {
    if (!bxHome.length) return <></>

    return (
        <div className={"mt-4"}>
            {bxHome.map((block: any, index: number) => (
                <BxHomeNarrativeBlock block={block} dailyDeal={dailyDeal} key={index} />
            ))}
        </div>
    )
}
