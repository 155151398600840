import single from "../../utils/single";
import VoucherCodeCopy from "./VoucherCodeCopy";
import React from "react";
import Link from "../link/Link";

type Props = React.PropsWithChildren<{ block: any; }>

export default function TileInteraction({ block, children }: Props) {
    const link = single(block.link) ?? '';
    const code = single(block.code) ?? '';

    if (code) {
        return <VoucherCodeCopy code={code}>
            {children}
        </VoucherCodeCopy>;
    } else if (link) {
        return <Link href={link} >
            {children}
        </Link>
    } else {
        return <>{children}</>;
    }
}
