import React from "react";
import Link from "../../link/Link";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import Image from "next/image";
import blogLinkService from "../../../services/blog/blogLinkService";
import {convertBxAttributes} from "../../../utils/bxAttributes";
import {imageSizes} from "../../../utils/imageSizes";
import single from "../../../utils/single";
import {parseBlogId} from "../../../utils/bxBlog";

interface Props {
    narrative: any;
}

function BxBlogTile({ block }: { block: any; }) {
    const bxHit = block['bx-hit'];

    return <Link {...convertBxAttributes(block, 'h-100 rounded-4')} href={single(bxHit.link) ?? blogLinkService.getLink({
        id: parseBlogId(single(bxHit.id)),
        title: single(bxHit.title) })}>
        <div>
            <Image
                width={380}
                height={232}
                sizes={imageSizes({
                    xs: 'calc(100vw - 24px)',
                    sm: '516px',
                    md: '227px',
                    lg: '307px',
                    xl: '367px',
                    xxl: '380px'
                })}
                src={single(bxHit.image)}
                alt={single(bxHit.title)}
                className="tile--image w-100 h-100 rounded-4"/>
        </div>
        <div className="mt-2 fw-bold">
            {single(bxHit.title)}
        </div>
    </Link>;
}

export default function BxBlogTileGrid({ narrative }: Props) {
    return <div {...convertBxAttributes(narrative, 'container mt-5 mb-5')}>
        {narrative.title &&
            <>
                <h2 className="mb-3">{narrative.title}</h2>
                <hr className="primary-color"/>
            </>
        }

        <Swiper
            slidesPerView={1}
            pagination={{clickable: true}}
            mousewheel={{forceToAxis: true}}
            modules={[Pagination, Mousewheel, FreeMode]}
            className="d-md-none pb-4">
            {narrative.blocks.map((block: any, index: number) =>
                <SwiperSlide key={index}>
                    <BxBlogTile block={block} />
                </SwiperSlide>
            )}
        </Swiper>

        <div className="tiles row g-2 d-none d-md-flex">
            {narrative.blocks.map((block: any, index: number) => (
                <div className="tile no-hover col-4" key={index}>
                    <BxBlogTile block={block} />
                </div>
            ))}
        </div>
    </div>;
}
