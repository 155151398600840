import Image from "next/image";
import {BxRichText} from "./RichText";
import React, {useEffect, useRef, useState} from "react";
import {convertBxAttributes} from "../../utils/bxAttributes";
import {imageSizes} from "../../utils/imageSizes";
import single from "../../utils/single";
import {Swiper, SwiperSlide, useSwiper, useSwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation, Pagination} from "swiper/modules";
import TileInteraction from "./TileInteraction";
import ResponsiveVideo from "./ResponsiveVideo";
import useVisibilityState from "../../hooks/useVisibilityState";

interface Props {
    narrative: any;
}

interface TileProps {
    block: any;
    mediaClassName?: string;
    autoplayDelay?: number;
}

function isVideo(url: string) {
    return url.endsWith('.webm') || url.endsWith('.mp4');
}

function Tile({block, mediaClassName = '', autoplayDelay}: TileProps) {
    const swiperSlide = useSwiperSlide();
    const swiper = useSwiper();
    const videoRef = useRef<HTMLVideoElement>(null);
    const visibilityState = useVisibilityState();
    const [shouldAdvance, setShouldAdvance] = useState(false);

    let videoClass = `w-100 h-auto ${mediaClassName}`;
    let hasVideo = false;
    const videoSources = {xs: '', md: ''};

    const url: string = single(block.image);
    if (isVideo(url)) {
        videoSources.xs = url;
        hasVideo = true;
        videoClass += ' d-block';
    } else {
        videoClass += ' d-none';
    }

    const desktopUrl: string = single(block['desktop-image']) ?? '';
    if (isVideo(desktopUrl)) {
        videoSources.md = desktopUrl;
        hasVideo = true;
        videoClass += ' d-md-block';
    } else {
        videoClass += ' d-md-none';
    }

    useEffect(() => {
        setShouldAdvance(false);
        if (!swiperSlide || !videoRef.current) {
            return;
        }

        // start video when slide is active
        if (swiperSlide.isActive) {
            videoRef.current.currentTime = 0;
            void videoRef.current.play();
        } else {
            videoRef.current.pause();
        }
    }, [swiperSlide?.isActive]);

    useEffect(() => {
        if (swiperSlide?.isActive && visibilityState === 'visible' && shouldAdvance) {
            swiper?.slideNext();
        }
    }, [swiperSlide?.isActive, visibilityState, shouldAdvance]);

    useEffect(() => {
        if (!autoplayDelay || !swiperSlide?.isActive) {
            return;
        }

        // in case the media is not a video, advance the slider automatically after a certain time
        const timeout = setTimeout(
            () => {
                if (
                    !hasVideo
                    || !videoRef.current
                    || getComputedStyle(videoRef.current).display === 'none'
                ) {
                    setShouldAdvance(true);
                }
            },
            autoplayDelay
        );
        return () => {
            clearTimeout(timeout);
        };
    }, [swiperSlide?.isActive, hasVideo, autoplayDelay]);

    const sharedProps = {
        alt: single(block['alt-text']) ?? '',
        sizes: imageSizes({
            xs: 'calc(100vw - 24px)',
            sm: '516px',
            md: '696px',
            lg: '936px',
            xl: '1089px',
            xxl: '1156px'
        })
    };

    return <div {...convertBxAttributes(block, 'd-block position-relative h-100')}>
        <TileInteraction block={block}>
            {hasVideo && <ResponsiveVideo
                sources={videoSources}
                className={videoClass}
                autoPlay={!swiperSlide}
                loop={!swiperSlide}
                muted
                disablePictureInPicture
                width={1156}
                height={396}
                playsInline
                onEnded={() => {
                    setShouldAdvance(true);
                }}
                ref={videoRef}
            />}
            {!videoSources.xs && <Image
                src={url}
                className={`object-fit-contain h-auto w-100 ${mediaClassName} ${desktopUrl ? 'd-md-none' : ''}`}
                width={1156}
                height={1156}
                {...sharedProps}
            />}
            {!videoSources.md && <Image
                src={desktopUrl}
                className={`object-fit-contain h-auto w-100 d-none d-md-block ${mediaClassName}`}
                width={1156}
                height={396}
                {...sharedProps}
            />}
            <div className="mt-1 fs-7"><BxRichText content={single(block.title)}/></div>
        </TileInteraction>
    </div>;
}

export default function BxHeroTileWrapper({narrative}: Props) {
    const tileBlocks: any[] = narrative.blocks;
    if (tileBlocks.length === 0) return <></>;

    const autoplayDelay = Number(single(narrative['autoplay-delay']));

    return <div {...convertBxAttributes(narrative, 'container mt-4')}>
        <div className="row g-2">
            <div className="col">
                {tileBlocks.length === 1
                    ? <Tile block={tileBlocks[0]}/>
                    : <Swiper
                        className="rounded-4 hero-tile-slider"
                        slidesPerView={1}
                        mousewheel={{forceToAxis: true}}
                        freeMode={{sticky: true}}
                        pagination={{clickable: true}}
                        modules={[Mousewheel, FreeMode, Navigation, Pagination]}
                        navigation={true}
                        loop={true}
                    >
                        {tileBlocks.map((block, index) => <SwiperSlide key={index}>
                            <Tile block={block} mediaClassName="mb-4" autoplayDelay={autoplayDelay}/>
                        </SwiperSlide>)}
                    </Swiper>
                }
            </div>
        </div>
    </div>;
}
