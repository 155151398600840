import slugify from "../slugify";
import i18next from "i18next";

interface BlogForLink {
    link?: string;
    id: number;
    title: string;
}

class BlogLinkService {
    getLink(blog: BlogForLink) {
        if (blog.link) return `/${i18next.language}/${blog.link.toLowerCase()}`;
        return `/${i18next.language}/bl/${slugify(blog.title.toLowerCase())}-${blog.id}`
    }
}

const blogLinkService = new BlogLinkService();
export default blogLinkService;
