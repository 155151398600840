import React from "react";
import Image from "next/image";
import {BxRichText} from "./RichText";
import single from "../../utils/single";
import {convertBxAttributes} from "../../utils/bxAttributes";
import {imageSizes} from "../../utils/imageSizes";
import TileInteraction from "./TileInteraction";

interface Props {
    narrative: any;
}

export default function BxTopicTileGrid({narrative}: Props) {
    return <div {...convertBxAttributes(narrative, 'container mt-5 mb-5')}>
        {narrative.title && (
            <>
                <h2 className="mb-3">{narrative.title}</h2>
                <hr className={"primary-color"}/>
            </>
        )}

        <div className="row g-2">
            {narrative.blocks.map((block: any, index: number) => (
                <div key={index}
                     {...convertBxAttributes(block, 'd-block col-6 col-md-4 position-relative no-hover rounded-4')}>
                    <TileInteraction block={block}>
                        <Image
                            width={380}
                            height={279}
                            src={single(block.image)}
                            sizes={imageSizes({
                                xs: 'calc(50vw - 16px)',
                                sm: '254px',
                                md: '227px',
                                lg: '307px',
                                xl: '367px',
                                xxl: '380px'
                            })}
                            alt={single(block['alt-text'])}
                            className="w-100 h-auto rounded-4 image-cover"/>
                        <div className="mb-2 mt-1">
                            <BxRichText content={block.title}/>
                        </div>
                    </TileInteraction>
                </div>
            ))}
        </div>
    </div>
}
