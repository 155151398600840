import React from "react";
import DailyDealTile from "./DailyDealTile";
import Link from "../link/Link";
import single from "../../utils/single";

interface Props {
    dailyDeal: any;
    blockData: any;
}

export default function DailyDeal({ dailyDeal, blockData }: Props) {
    if (!dailyDeal) return <></>;

    return <div className="bg-secondary rounded-4 mt-2 p-1">
        <div className="bg-white rounded-4 pt-3 pb-3 position-relative home-deal-wrapper">
            {/* DailyDeal has a link inside of it, which is not allowed to be in another link */}
            <Link href={single(blockData.link)} className="d-block dark position-absolute w-100 h-100 top-0 start-0 z-index-10" />

            <h2 className="mb-3 ms-2">{dailyDeal.title}</h2>
            <hr className={"text-black ms-2 me-2"}/>

            <DailyDealTile
                product={dailyDeal}
                productTile={false}
                className={"m-md-4 px-3 p-md-1 rounded-4"}
                showDescription={false}
                style={{maxWidth: '800px'}}
                showWide={false}
                showCart={false}
            />
        </div>
    </div>;
}
