import {useSyncExternalStore} from "react";

export default function useVisibilityState(): DocumentVisibilityState {
    return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
};

function subscribe(callback: () => void) {
    document.addEventListener('visibilitychange', callback)

    return () => {
        document.removeEventListener('visibilitychange', callback);
    };
}

function getSnapshot() {
    return document.visibilityState;
}

function getServerSnapshot() {
    return 'visible' as const;
}
