import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Pagination} from "swiper/modules";
import ProductTile from "../product/Tile";
import {convertBxAttributes} from "../../utils/bxAttributes";
import single from "../../utils/single";

interface Props {
    narrative: any;
    dailyDeal?: any;
}

export default function BxProductSlider({ narrative, dailyDeal }: Props) {
    if (narrative.blocks.length === 0) return <></>;

    const breakpoints = {
        1: {
            slidesPerView: 1
        },
        340: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: dailyDeal ? 2 : 3
        },
        992: {
            slidesPerView: dailyDeal ? 3 : 4
        },
        1200: {
            slidesPerView: dailyDeal ? 4 : 5
        },
    }

    const title = single(narrative.title);
    return <div {...convertBxAttributes(narrative, 'bg-highlight mt-1')}>
        <div className={`bg-white pt-4 pb-4 ${!dailyDeal ? 'container' : ''}`}>
            {title && (
                <>
                    <h2 className="mb-3">{title}</h2>
                    <hr className={"primary-color"}/>
                </>
            )}

            <Swiper pagination={{clickable: true}}
                    breakpoints={breakpoints}
                    mousewheel={{forceToAxis: true}}
                    freeMode={{sticky: true}}
                    modules={[Pagination, Mousewheel, FreeMode]}
                    className="product-slider pb-4"
                    preventClicks={true}
                    loop={false}>
                {narrative.blocks.map((product: any, index: number) => (
                    <SwiperSlide key={index}>
                        <ProductTile product={product['bx-hit'] ?? product}/>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    </div>;
}
