import React from "react";
import Link from "../link/Link";
import Image from "next/image";
import {BxRichText} from "./RichText";
import {imageSizes} from "../../utils/imageSizes";
import single from "../../utils/single";

interface Props {
    narrative: any;
}

export default function BxHeroTile({narrative}: Props) {
    return <div className="container mt-5">
        <div className="row g-2">
            <Link className="col" href={single(narrative.link)}>
                <Image
                    src={single(narrative.image)}
                    width={1156}
                    height={396}
                    sizes={imageSizes({
                        xs: 'calc(100vw - 24px)',
                        sm: '516px',
                        md: '696px',
                        lg: '936px',
                        xl: '1089px',
                        xxl: '1156px'
                    })}
                    alt={single(narrative['alt-text'])}
                    priority
                    className="object-fit-contain h-auto w-100 rounded-4"
                />
                <div className="mt-1 fs-7"><BxRichText content={narrative.title}/></div>
            </Link>
        </div>
    </div>;
}

